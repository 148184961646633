// CommentComponent.js
import React from "react";
import { MentionsInput, Mention } from "react-mentions";
import { Modal } from "antd";
import { MESSAGES, CONFIRMATION_BOX } from "../../helpers/Constants.js";
import "./CommentComponent.css";
const moment = require("moment");

class CommentComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputDisable: true,
      commentText: props.comment.commentText,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.comment.commentText !== this.props.comment.commentText
    ) {
      this.setState({ commentText: this.props.comment.commentText });
    }
  }

  render() {
    const {
      comment,
      deleteComments,
      updateComments,
      closeComments,
      contributionDetails,
    } = this.props;
    const { commentText, inputDisable } = this.state;

    // Update commentText in state when the input changes
    const commentOnchange = (value) => {
      this.setState({ commentText: value });
    };

    const editComments = () => {
      this.setState({ inputDisable: false });
    };

    const handleDeleteComments = (id) => {
      const confirm = Modal.confirm;
      confirm({
        title: CONFIRMATION_BOX.CONFIRMATION_TITLE,
        content: MESSAGES.DELETE_COMMENT_MESSAGE,
        okText: CONFIRMATION_BOX.CONFIRMATION_MODAL_OK,
        cancelText: CONFIRMATION_BOX.CONFIRMATION_MODAL_CANCEL,
        onOk() {
          deleteComments(id);
        },
        onCancel() {
          return false;
        },
      });
    };

    const handleCloseComments = () => {
      const { comment } = this.props;
      this.setState({
        inputDisable: true,
        commentText: comment.commentText, // Reset to original text
      });
      closeComments();
    };

    const handleUpdateComments = (id) => {
      updateComments(id, commentText);
      this.setState({ inputDisable: true });
    };

    return (
      <div className="commentCard">
        <div className="details">
          <h3 className="highlight_text">
            <b>{comment.commentUser}</b>
          </h3>
          <h4>{moment(comment.commentDate).format("DD-MM-YYYY h:mm:ss")}</h4>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
            <MentionsInput
              value={commentText} // Use state commentText
              ref={(inputRef) => (this.inputRef = inputRef)}
              onChange={(e) => commentOnchange(e.target.value)}
              markup="@{{__type__||__id__||__display__}}"
              className="mentions"
              disabled={inputDisable}
            >
              {
                contributionDetails && (
                  <Mention
                    type="user"
                    trigger="@"
                    data={contributionDetails.map((myUser) => ({
                      id: myUser.userDetails.uid,
                      display: `${myUser.userDetails.firstName} ${myUser.userDetails.secondName}`,
                    }))}
                    className="mentions__mention"
                  />

                )
              }
            </MentionsInput>
          <div>
            {comment.handleAction && inputDisable && (
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                }}
                className="commentSecBtn"
              >
                <button
                  title="Edit"
                  className="clr_icon"
                  onClick={() => editComments(comment.id)}
                >
                  <i
                    aria-hidden="true"
                    className="icon anticon anticon-edit"
                  ></i>
                </button>
                <button
                  title="Delete"
                  className="clr_icon"
                  onClick={() => handleDeleteComments(comment.id)}
                >
                  <i
                    aria-hidden="true"
                    className="icon anticon anticon-delete"
                  ></i>
                </button>
              </div>
            )}
            {!inputDisable && (
              <div
                style={{
                  display: "flex",
                  gap: "15px",
                }}
                className="commentSecBtn"
              >
                <button
                  disabled={commentText === ""}
                  title="Save"
                  className="clr_icon"
                  onClick={() => handleUpdateComments(comment.id)}
                >
                  <i
                    aria-hidden="true"
                    className="icon anticon anticon-save"
                  ></i>
                </button>
                <button
                  title="Close"
                  className="clr_icon"
                  onClick={() => handleCloseComments(comment.id)}
                >
                  <i
                    aria-hidden="true"
                    className="icon anticon anticon-close"
                  ></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CommentComponents;
