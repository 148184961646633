import React from "react";
import { Button, Icon } from 'semantic-ui-react';
import { Select, LocaleProvider, Avatar, Modal as AntModal, Radio } from 'antd';
import { showAlert } from "../../helpers/commonFunctions";
import en_US from 'antd/lib/locale-provider/en_US';
import { validEmail } from '../../helpers/Utils';
import { getUsersByOrgId } from "../../Services/WorkspaceApis";
class ShareProjects extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getUsersByOrgIdCallBack = this.getUsersByOrgIdCallBack.bind(this);    

        this.state = {
          submitDisabled: true,
          modalOpen: true,
          formValue: "",
          admin: false,
          userOption: [],
          inviteUserEmailList: [],
          orgUsers: [],
          adhocUser: false,
          guestUser: false,
          qaUser: false,
          userType: 2,
          loading: true,
        };
      }

      componentDidMount() {
        getUsersByOrgId(this.props.orgId, this.getUsersByOrgIdCallBack, this.state.userType)
      }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => { this.setState({ modalOpen: false }); this.props.modalClose(); }

    handleSubmit = () => {
        let invalidEmails=[];
        this.state.inviteUserEmailList.forEach(email=>{
          if(!validEmail(email)){
            invalidEmails.push(email);
          }
        });
        if(invalidEmails.length<=0){
          this.props.submitEmail(this.state.inviteUserEmailList.join(","), this.state.admin);
          this.setState({ modalOpen: false }); 
          this.props.modalClose();
        }else{
          showAlert("Please enter valid email(s)","error")
        }
      }
    
      getUsersByOrgIdCallBack(err, res){
        let users = res.body.userInfos
        this.setState({orgUsers: users});
        let userOption = [];
        this.state.orgUsers.forEach( user => {
          let badge = '';
          if( user.roleId === 2 ) {
            badge = <Avatar shape="square" style={{ backgroundColor: '#00b33c', width:'20px'}}>A</Avatar>
          } else if ( user.roleId === 3 ){
            badge = <Avatar shape="square" style={{ backgroundColor: '#8d8e8b', width:'20px'}}>U</Avatar>
          } else if ( user.roleId === 5 ){
            badge = <Avatar shape="square" style={{ backgroundColor: '#C9E343', width:'20px'}}>G</Avatar>
          } else if ( user.roleId === 6 ){
            badge = <Avatar shape="square" style={{ backgroundColor: '#43BCE3', width:'20px'}}>Q</Avatar>
          }
          userOption.push(<option key={user.email} value={user.email} label={user.email}>{user.email} {badge}</option>)
        });
        this.setState({loading : false, userOption}, () => {
          document.querySelector('.ant-select-search__field').click()
        });
      }
    
      handleChange = (elements) => { 
        let idList = [];
        let adhocUserFound = false;
        elements.forEach( element => {
          if ( this.state.orgUsers.filter( orgUser => orgUser.email === element ) <=  0 ) {
            adhocUserFound = true;
          }
            idList.push(element);
        });
        this.setState({adhocUser: adhocUserFound});
        let disabledButton = idList.length > 0 ? false : true;
        this.setState({inviteUserEmailList: idList, submitDisabled: disabledButton});
      }

      handleRoleChangeGuest = e => {
        this.setState({
          userType: e.target.value
        });
        getUsersByOrgId(this.props.orgId, this.getUsersByOrgIdCallBack, e.target.value);
      };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <AntModal
          className="m_model"
          title="Add Contributor(s) to the Project"
          visible={this.state.modalOpen}
          onOk={this.handleOk}
          onCancel={this.handleClose}
          footer={[
            <Button onClick={this.handleClose} className="pull-left b-r-20">
              Close
            </Button>,
            <Button
              id="submitgreen_btn"
              className="b-r-20"
              inverted
              type="submit"
              disabled={this.state.submitDisabled}
              onClick={this.handleSubmit}
            >
              <Icon name="checkmark" /> Submit
            </Button>,
          ]}
        >
          <div className="m_height">
            <p>
              Annotator can view, tag or update HIT tags and the Admin will have
              all permissions except delete project : modify or add data to the
              project, and add other contributors.{" "}
            </p>

            <div className="m-t-10 m-b-5">
              <h4 className="m-t-5 m-b-5"> Role:</h4>
              <Radio.Group
                onChange={this.handleRoleChangeGuest}
                value={this.state.userType}
              >
                <Radio value={2}>Delegate Admin</Radio>
                <Radio value={3}>Annotator</Radio>
                <Radio value={5}>Guest</Radio>
                <Radio value={6}>QA</Radio>
              </Radio.Group>
            </div>
            <div className="legend_icon m-b-5">
              <div className="h_legend m-r-15">
                <span className="legand_box admin">A</span> - Admin
              </div>
              <div className="h_legend m-r-15">
                <span className="legand_box user">U</span> - User
              </div>
              <div className="h_legend m-r-15">
                <span className="legand_box guest">G</span> - Guest
              </div>
              <div className="h_legend">
                <span className="legand_box qa">Q</span> - QA
              </div>
            </div>
            <LocaleProvider locale={en_US}>
              <Select
                className="model_select"
                mode="multiple"
                dropdownClassName="m_d_select"
                defaultOpen
                showSearch
                placeholder="Select user(s) to add"
                onChange={this.handleChange}
                style={{ width: "100%", overflowY: "scroll" }}
                notFoundContent={loading ? "loading" : "Not Found"}
              >
                {this.state.userOption}
              </Select>
            </LocaleProvider>
          </div>
        </AntModal>
      </div>
    );
  }
}

export default ShareProjects
