import React from "react";
import { Icon as AntIcon, Switch } from "antd";
import { Icon } from "semantic-ui-react";
import { dateTimeConverter } from "../../helpers/Utils";
import { TOOLTIP } from "../../helpers/Constants";
import { IMAGE_MASKING, IMAGE_SEGMENTATION } from "../../helpers/Utils";
class ColorContrastBar extends React.Component {
  render() {
    const {
      contrast,
      saturation,
      brightness,
      zoomFactor,
      taskType,
      opacity,
      fullScreen,
      drawHandle,
      _panner,
      hits,
      annotatedLabelCount,
      rects,
      currentIndex,
      setretainAnnotations,
      dragAnnotation,
      dragEnable,
      selectAnnotations,
      lockHits,
      lockSingleAnonotation,
      lockAnnotationChange,
      setQACheck,
      hideImage,
      setRefCheck,
      imageRotate,
      qaCheck,
      refCheck,
      magnifierOption,
      setMagnifier,
      changeImageProp,
      lock,
      lockAnnotation,
      retainAnnotations,
      imageNiri,
    } = this.props;
    return (
      <div
        style={{
          backgroundColor: "#ABA8A8",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {drawHandle && (
          <div
            className="w_header"
            style={{
              fontSize: "xx-small",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <div className="l_parent_align" style={{ alignItems: "flex-end" }}>
              <label className="m-r-10">
                <span className="l_white">
                  Contrast : <b> {Math.round(contrast * 100)}%</b>{" "}
                </span>
                <input
                  type="range"
                  className="ui range range_slider mousetrap"
                  step="0.01"
                  min="0"
                  max="10"
                  value={contrast}
                  onChange={changeImageProp.bind(this, "contrast")}
                />
              </label>
              <label className="m-r-10">
                <span className="l_white">
                  Saturation : <b> {Math.round(saturation * 100)}% </b>{" "}
                </span>
                <input
                  type="range"
                  className="range_slider mousetrap"
                  step="0.01"
                  min="0"
                  max="4"
                  value={saturation}
                  onChange={changeImageProp.bind(this, "saturation")}
                />
              </label>
              <label className="m-r-10">
                <span className="l_white">
                  Brightness : <b> {Math.round(brightness * 100)}% </b>
                </span>
                <input
                  type="range"
                  className="range_slider mousetrap"
                  step="0.01"
                  min="0"
                  max="4"
                  value={brightness}
                  onChange={changeImageProp.bind(this, "brightness")}
                />
              </label>
              {_panner && _panner.scale && (
                <label className="m-r-10">
                  <span className="l_white">
                    Zoom :{" "}
                    <b>
                      {" "}
                      {Math.round(
                        zoomFactor === undefined
                          ? _panner.scale * 100
                          : zoomFactor * 100
                      )}
                      %
                    </b>{" "}
                  </span>

                  <input
                    type="range"
                    className="range_slider mousetrap"
                    step="0.01"
                    min="0"
                    max="20"
                    value={_panner.scale}
                    onChange={changeImageProp.bind(this, "scale")}
                  />
                </label>
              )}
              <label>
                <span className="l_white">
                  Box Opacity : <b> {Math.round(opacity * 100)}%</b>{" "}
                </span>
                <input
                  type="range"
                  className="ui range range_slider mousetrap"
                  step="0.01"
                  min="0"
                  max="1"
                  value={opacity}
                  onChange={changeImageProp.bind(this, "opacity")}
                />
              </label>
              {fullScreen && (
                <label>
                  <span className="l_white filewrap">
                  File Name :
                  <b title={hits[currentIndex].fileName}>{(hits[currentIndex].fileName).replaceAll("__-__-", " ")}</b>
                    </span>
                  <br></br>
                </label>
              )}
              {fullScreen && (
                <label>
                  <span className="l_white">
                    Last Updated:{" "}
                    <b>
                      {hits[currentIndex].hitResults
                        ? dateTimeConverter(
                            hits[currentIndex].hitResults[0].updatedTimestamp
                          )
                        : null}
                    </b>
                  </span>
                  <br></br>
                </label>
              )}
              <label>
                <span className="l_white l_align">
                  Label Count : {annotatedLabelCount}
                </span>
              </label>
            </div>
            <div className="menu_strip_con">
              {(taskType === IMAGE_SEGMENTATION ||
                taskType === IMAGE_MASKING) &&
                rects.length > 0 && (
                  <Switch
                    title={!retainAnnotations ? "Retain Annotation" : "Unretain Annotaiton"}
                    className="testy"
                    size="small"
                    checked={retainAnnotations}
                    onChange={setretainAnnotations}
                  />
                )}
              {rects.length > 0 && (
                <span className="annotCheck drag">
                  <input
                    type="checkbox"
                    className="mousetrap"
                    id="shield alternate"
                    onChange={dragAnnotation}
                    title={!dragEnable ? "Drag Annotations" : "Disable Drag Annotations" }
                  ></input>
                  <label></label>
                </span>
              )}
              {(taskType === IMAGE_SEGMENTATION ||
                taskType === IMAGE_MASKING) &&
                rects.length > 0 && (
                  <span className="annotCheck selectAll">
                    <input
                      type="checkbox"
                      className="mousetrap"
                      id="circle outline"
                      onChange={selectAnnotations}
                      title= {!lockHits ? "Select Annotations" : "DeSelect Annotations" } 
                    ></input>
                    <label></label>
                  </span>
                )}

              {(taskType === IMAGE_SEGMENTATION ||
                taskType === IMAGE_MASKING) &&
                rects.length > 0 && (
                  <button
                    title="Lock Single Annotation"
                    onClick={() => lockSingleAnonotation(true)}
                    style={{
                      backgroundColor:
                        lock === true ? "#12C4FC" : null,
                    }}
                    className="rotateImg"
                  >
                    <Icon name="lock" className="rotateImgIcon" />
                  </button>
                )}
              {(taskType === IMAGE_SEGMENTATION ||
                taskType === IMAGE_MASKING) &&
                rects.length > 0 && (
                  <button
                    title="UnLock Single Annotations"
                    onClick={() => lockSingleAnonotation(false)}
                    style={{
                      backgroundColor:
                        lock === false ? "#12C4FC" : null,
                    }}
                    className="rotateImg"
                  >
                    <Icon name="lock open" className="rotateImgIcon" />
                  </button>
                )}

          {(taskType === IMAGE_SEGMENTATION ||
                taskType === IMAGE_MASKING) &&
                rects.length > 0 && (
                  <button
                    title={!lockAnnotation ? TOOLTIP.LOCK_ANNOTATIONS : TOOLTIP.UNLOCK_ANNOTATIONS }
                    onClick={()=> !lockAnnotation ? lockAnnotationChange(true) : lockAnnotationChange(false) }
                    className="rotateImg"
                  >
                    <Icon name={!lockAnnotation ? "pause circle" : "play circle"} className="rotateImgIcon" />
                  </button>
                )}


              {taskType === IMAGE_SEGMENTATION || taskType === IMAGE_MASKING ? (
                <Switch
                  title={
                    qaCheck
                      ? TOOLTIP.HIDE_LABEL_SECTIONS
                      : TOOLTIP.SHOW_LABEL_SECTIONS
                  }
                  className="testy"
                  size="small"
                  checked={qaCheck}
                  onChange={setQACheck}
                />
              ) : null}
              {taskType === IMAGE_MASKING && imageNiri !== null ? (
                <Switch
                  title={
                    !refCheck
                      ? TOOLTIP.HIDE_REFERENCE_IMG
                      : TOOLTIP.SHOW_REFERENCE_IMG
                  }
                  className="testy"
                  style={{ margin: "10px" }}
                  id="btnnu"
                  size="small"
                  checked={refCheck}
                  onClick={() => hideImage()}
                  onChange={setRefCheck}
                />
              ) : null}
              <Switch
                title={!magnifierOption ? "Magnifier" : "DeMagnifier"} 
                checked={magnifierOption}
                onChange={setMagnifier}
                className="zoomIn"
              />
              <button
                title="Rotate Image"
                onClick={() => imageRotate()}
                className="rotateImg"
              >
                <Icon name="refresh" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ColorContrastBar
